import React from 'react'
import Fab from '@mui/material/Fab'
import FileCopy from '@mui/icons-material/FileCopy'
import Tooltip from '@mui/material/Tooltip'
import './gdrive.css'

class GDrive extends React.Component {
  loadPicker = () => {
    window.loadPicker(this.props.callback)
  }
  openFolder = () => {
    this.props.openFolder()
  }
  render(){
    return (
      <Tooltip title="Open Folder" placement="top">
        <Fab
          color="secondary"
          onClick={this.openFolder}
          className="open-recipe-fab">
          <FileCopy />
        </Fab>
      </Tooltip>
    )
  }
}

export default GDrive
