import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import './AppBar.css'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import EditIcon from '@mui/icons-material/Edit'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import Tooltip from '@mui/material/Tooltip'
import Badge from '@mui/material/Badge'
import ScaleField from './ScaleField'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      metric: props.metric,
      scale: props.scale,
      menuOpen: props.menuOpen
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let { metric, scale, menuOpen } = this.props
    if (metric !== this.state.metric || scale !== this.state.scale || menuOpen !== this.state.menuOpen) {
      this.setState({
        scale: scale,
        metric: metric,
        menuOpen: menuOpen
      })
    }
  }
  toggleMetric = () => {
    this.props.setMetric(!this.state.metric)
  }
  scale = event => {
    let scale = event.target.value
    this.props.setScale(scale)
  }
  toggleEditor = () => {
    this.props.edit()
  }
  toggleShoppingList = () => {
    this.props.toggleShoppingList()
  }
  render() {
    let { scale, menuOpen } = this.state
    let shoppingListLength = this.props.shoppingList.length
    return (
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={this.props.toggleMenu}
            size="large">
            <MenuIcon open={menuOpen} />
          </IconButton>
          <div className="app-bar-separator" />
          <Typography variant="h6" noWrap>
            Coucou Café Recipes
          </Typography>
          <div className="app-bar-separator" />
          <Tooltip title="Edit/Create Recipe" placement="bottom">
            <IconButton
              edge="start"
              aria-haspopup="true"
              onClick={this.toggleEditor}
              color="inherit"
              size="large">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Shopping List" placement="bottom">
            <IconButton
              edge="end"
              aria-haspopup="true"
              onClick={this.toggleShoppingList}
              color="inherit"
              size="large">
              <Badge badgeContent={shoppingListLength} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <div className="app-bar-separator" />
          <div maxwidth="xs" className="controls">
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.metric}
                  onChange={this.toggleMetric}
                  value="metric"
                  color="secondary"
                />
              }
              label="Metric"
            />
            <ScaleField
              id="standard-number"
              label="Scale"
              value={scale}
              onChange={this.scale}
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              margin="none"
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Header
