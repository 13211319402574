import React, { Component } from 'react'
import math from 'mathjs'
import CircularProgress from '@mui/material/CircularProgress'
// import cn from 'classnames/bind'
// import './countdown.css'

//const cx = cn.bind(styles)

class Timer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0,
      time_up: "",
      deadline: this.props.deadline,
      started: this.props.started,
      progress: 0
    }
    this.x = null
    this.time_split = ["day", "hour", "minute", "second"]
  }
  count = () => {
    let now = new Date().getTime();
    let t = this.state.deadline - now;
    let time_values = math.unit(t, "ms").splitUnit(this.time_split)
    let [days, hours, minutes, seconds] = time_values.map((unit, index) => (
      math.format(unit.toNumber(this.time_split[index]), { precision: 2 })
    ))
    let progress = (1 - t / this.props.duration) * 100;
    this.setState({ days, minutes, hours, seconds, progress })
    if (t < 0) {
      clearInterval(this.x);
      this.setState({ days: 0, minutes: 0, hours: 0, seconds: 0, time_up: "TIME IS UP", progress: 0 })
      if (Notification.permission === "granted") {
        new Notification(this.props.message);
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let { deadline, started } = this.props
    if (deadline !== this.state.deadline || started !== this.state.started) {
      this.setState({
        started: started,
        deadline: deadline
      })
      if (started) {
        this.x = setInterval(this.count, 200)
      }
      else {
        clearInterval(this.x)
      }
    }
  }

  render() {
    const { seconds, hours, minutes, started, progress, deadline } = this.state
    let now = new Date().getTime()
    return (
      <span>
        {(started && deadline > now) &&
          <span className="hours" id="hour">
            {hours}:{minutes}:{seconds}
            <CircularProgress
              variant="determinate"
              value={progress}
              color="primary"
            />
          </span>
        }
      </span>
    );
  }
}

export default Timer
