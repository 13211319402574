import React from 'react'
import AlarmIcon from '@mui/icons-material/Alarm'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import './RecipeDisplay.css'
import Timer from './Timer.js'
import { toMetric, toImperial } from './ingredients'
import { unit, round } from 'mathjs'


class RecipeDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      metric: props.metric,
      scale: props.scale
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let { metric, scale } = this.props
    if (metric !== this.state.metric || scale !== this.state.scale) {
      this.setState({
        scale: scale,
        metric: metric
      })
    }
  }
  addToShoppingList = () => {
    this.props.addToShoppingList(this.props.fileId)
  }
  render() {
    const { scale, metric } = this.state
    let sections = this.props.sections.map((section, index) =>
      <RecipeSection
        key={index}
        ingredients={section.ingredients}
        steps={section.steps}
        id={index}
        title={section.title}
        metric={metric}
        scale={scale}
      />
    )
    const divider = <Divider variant="middle" light key={Math.random()} />
    return (
      <Container className="recipe-display" maxwidth="lg">
        <Paper className="recipe-display-paper">
          <Tooltip title="Add to Shopping List" placement="top">
            <IconButton
              edge="end"
              aria-haspopup="true"
              onClick={this.addToShoppingList}
              color="secondary"
              className="add-to-cart"
              size="large">
              <AddShoppingCartIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h2" component="h2" className="recipe-title">
            {this.props.title}
          </Typography>
          <Container className="recipe-sections">
            {sections.reduce((prev, next) => [prev, divider, next])}
          </Container>
        </Paper>
      </Container>
    );
  }
}

class RecipeSection extends React.Component {
  render() {
    let steps = (
      <List>
        {
          this.props.steps.map((step, index) =>
            <RecipeStep
              step={step}
              key={index}
              id={index}
              metric={this.props.metric}
            />
          )
        }
      </List>
    )
    let ingredients = (
      <List>
        {
          this.props.ingredients.map((ingredient, index) =>
            <RecipeIngredient
              ingredient={ingredient}
              key={index}
              id={index}
              metric={this.props.metric}
              scale={this.props.scale}
            />
          )
        }
      </List>
    )
    return (
      <Container className="recipe-section">
        <Typography variant="h4" component="h4" className="recipe-section-title">{this.props.title}</Typography>
        <Container className="recipe-section-ingredients">
          <Typography variant="h5" component="h5" className="recipe-section-ingredients-title">Ingredients</Typography>
          {ingredients}
        </Container>
        <Container className="recipe-section-steps">
          <Typography variant="h5" component="h5" className="recipe-section-instructions-title">Instructions</Typography>
          {steps}
        </Container>
      </Container>
    )
  }
}

class RecipeStep extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false
    }
  }
  check = () => {
    this.setState({
      checked: !this.state.checked
    })
  }
  parse(step) {
    let timer = /(#timer\((.*?)\)|#temp\((.*?)\))/g
    let match
    let matches = []
    while ((match = timer.exec(step)) !== null) {
      matches.push(match)
    }
    if (matches.length > 0) {
      return matches.map((match, index) => {
        let prefix = step.slice(0, match.index)
        let suffix = step.slice(match.index + match[0].length, step.length)
        if (index > 0) {
          prefix = ""
        }
        if (matches.length > index + 1) {
          let next_match = matches[index + 1]
          suffix = step.slice(match.index + match[0].length, next_match.index)
        }
        if (match[2] === undefined) {
          return (
            <RecipeTemp
              prefix={prefix}
              temp={match[3]}
              suffix={suffix}
              metric={this.props.metric}
              key={index}
            />
          )
        }
        return (
          <RecipeTimer
            prefix={prefix}
            duration={match[2]}
            suffix={suffix}
            key={index}
          />
        )
      })
    }
    return step
  }
  render() {
    let step = this.parse(this.props.step)
    return (
      <ListItem
        key={this.props.id}
        dense
      //onClick={this.check}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            tabIndex={-1}
            checked={this.state.checked}
            disableRipple
            color="primary"
            onClick={this.check}
          />
        </ListItemIcon>
        <ListItemText primary={
          <Typography variant="body1" component="span" >{step}</Typography>
        } />
      </ListItem>
    )
  }
}

class RecipeIngredient extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false
    }
  }
  check = () => {
    this.setState({
      checked: !this.state.checked
    })
  }
  render() {
    let ingredient = this.props.ingredient
    let quantity = ingredient.quantity * this.props.scale
    let data = `${quantity} ${ingredient.unit} ${ingredient.item}`

    let metric = this.props.metric
    let convert = metric ? toMetric : toImperial
    let converted = convert(ingredient.item, quantity, ingredient.unit)
    if (converted !== null) {
      let values = converted.value.map((value, index) => {
        if (value > 0.125) {
          return `${value} ${converted.unit[index]}`
        }
        return ''
      }).filter((value) => value.length > 0)
      data = `${values.join(', ')} ${ingredient.item}`
    }
    return (
      <ListItem
        key={this.props.id}
        dense
      //onClick={this.check}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            tabIndex={-1}
            checked={this.state.checked}
            disableRipple
            color="primary"
            onClick={this.check}
          />
        </ListItemIcon>
        <ListItemText primary={
          <Typography variant="body1">{data}</Typography>
        } />
      </ListItem>
    )
  }
}

class RecipeTimer extends React.Component {
  constructor(props) {
    super(props)
    this.duration = unit(this.props.duration).toNumber('ms')
    this.state = {
      deadline: Date.now() + this.duration,
      started: false,
      message: `${this.props.prefix} ${this.props.duration} ${this.props.suffix}`
    }
  }
  start = () => {
    this.setState(state => {
      if (!state.started) {
        return {
          started: !state.started,
          deadline: Date.now() + this.duration
        }
      }
      return {
        started: !state.started
      }
    })
  }
  render() {
    let { deadline, started, message } = this.state
    return (
      <Typography variant="body1" component="span">
        <span> {this.props.prefix} </span>
        <span> {this.props.duration} </span><AlarmIcon color="primary" onClick={this.start} />
        <Timer deadline={deadline} started={started} message={message} duration={this.duration} />
        <span> {this.props.suffix} </span>
      </Typography>
    )
  }
}

class RecipeTemp extends React.Component {
  render() {
    const { metric, temp, prefix, suffix } = this.props
    let temperature = unit(`${temp.split(",")[0]} deg${temp.split(",")[1].trim()}`)
    if (metric) {
      temperature = round(temperature.to("degC").toNumber(), 0) + "°C"
    }
    else {
      temperature = round(temperature.to("degF").toNumber(), 0) + "°F"
    }
    return (
      <Typography variant="body1" component="span">
        <span>{prefix}</span>
        <span>{temperature}</span>
        <span>{suffix}</span>
      </Typography>
    )
  }
}

export default RecipeDisplay;
