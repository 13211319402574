import React from 'react'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import List from '@mui/material/List'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import { toMetric, toImperial } from './ingredients'
import './ShoppingList.css'
import * as math from "mathjs"


class ShoppingList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: this.props.list,
      scales: this.initializeScales(),
      ingredients: {},
      checked: this.initializeChecked()
    }
  }
  deleteFromList = (fileId) => {
    this.props.delete(fileId)
    let scales = this.state.scales
    delete scales[fileId]
    this.setState({
      scales: scales
    })
  }
  initializeScales = () => {
    let scales = {}
    if (this.state) {
      scales = this.state.scales
    }
    for (let fileId in this.props.list) {
      if (!(fileId in scales)) {
        scales[fileId] = 1
      }
    }
    return scales
  }
  initializeChecked = () => {
    let checked = {}
    if (this.state) {
      checked = this.state.checked
      for (let item in this.state.ingredients) {
        if (!(item in checked)) {
          checked[item] = false
        }
      }
      return checked
    }
    return {}
  }
  check = (item) => {
    let checked = this.state.checked
    if (item in checked) {
      checked[item] = !checked[item]
    }
    this.setState({
      checked: checked
    })
  }
  update = (value, fileId) => {
    let scales = this.state.scales
    scales[fileId] = parseFloat(value)
    this.setState({
      scales: scales
    })
  }
  computeIngredients = () => {
    let ingredients = {}
    for (let fileId in this.state.list) {
      const scale = this.state.scales[fileId]
      const recipe = this.props.list[fileId]
      for (let section of recipe.sections) {
        for (let ingredient of section.ingredients) {
          if (ingredient.item in ingredients) {
            const savedIngredient = ingredients[ingredient.item]
            if (savedIngredient.unit === ingredient.unit) {
              ingredients[ingredient.item].quantity += scale * ingredient.quantity
            }
            else {
              const a = toMetric(ingredient.item, savedIngredient.quantity, savedIngredient.unit, true)
              const b = toMetric(ingredient.item, scale * ingredient.quantity, ingredient.unit, true)
              const c = math.add(a.value, b.value)
              ingredients[ingredient.item] = {
                quantity: c[0],
                unit: a.unit[0]
              }
            }
          }
          else {
            ingredients[ingredient.item] = {
              quantity: scale * ingredient.quantity,
              unit: ingredient.unit
            }
          }
        }
      }
    }
    return ingredients
  }
  componentDidUpdate(prevProps, prevState) {
    const ingredients = this.computeIngredients()
    if (prevProps.list !== this.props.list) {
      this.setState({
        list: this.props.list,
        scales: this.initializeScales()
      })
    }
    if (JSON.stringify(ingredients) !== JSON.stringify(this.state.ingredients)) {
      this.setState({
        ingredients: ingredients
      })
    }
  }
  componentWillMount() {
    const ingredients = this.computeIngredients()
    if (JSON.stringify(ingredients) !== JSON.stringify(this.state.ingredients)) {
      this.setState({
        ingredients: ingredients
      })
    }
  }
  render() {
    const { list, scales, ingredients, checked } = this.state
    const listItems = Object.keys(list).map((fileId) => (
      <ListItem
        key={fileId}
      >
        <ListItemIcon>
          <TextField
            label="Scale"
            className="recipe-scale"
            value={scales[fileId]}
            onChange={(event) => this.update(event.target.value, fileId)}
            margin="normal"
            type="number"
          />
        </ListItemIcon>
        <ListItemText primary={list[fileId].title} />
        <ListItemSecondaryAction>
          <Tooltip title="Delete from list" placement="right">
            <IconButton
              edge="end"
              aria-label="Delete"
              onClick={() => this.deleteFromList(fileId)}
              size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    ))
    const divider = () => <Divider variant="middle" key={Math.random()} />
    const nonZeroIngredients = Object.keys(ingredients).filter(item => (
      ingredients[item].quantity > 0
    ))
    return (
      <Container className="shopping-list">
        <Typography variant="h2" component="h2" className="shopping-list-title">
          Shopping List
        </Typography>
        <Container maxWidth="sm" className="recipe-list">
          {listItems.length > 0 &&
            <Paper>
              <List>
                {
                  listItems.reduce((prev, curr) => [prev, divider(), curr])
                }
              </List>
            </Paper>
          }
        </Container>
        {listItems.length > 0 &&
          nonZeroIngredients.length > 0 &&
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonZeroIngredients.map(item => {
                  const ingredient = ingredients[item]
                  let data = `${ingredient.quantity} ${ingredient.unit}`
                  let convert = this.props.metric ? toMetric : toImperial
                  let converted = convert(
                    item,
                    ingredient.quantity,
                    ingredient.unit
                  )
                  if (converted !== null) {
                    let values = converted.value.map((value, index) => {
                      if (value > 0.125) {
                        return `${value} ${converted.unit[index]}`
                      }
                      return ''
                    }).filter((value) => value.length > 0)
                    data = `${values.join(', ')}`
                  }
                  return (
                    <TableRow key={item}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={checked[item]}
                          color="primary"
                          onClick={() => this.check(item)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" className="name">
                        {item}
                      </TableCell>
                      <TableCell align="right">
                        {data}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
        }
      </Container>
    )
  }
}

export default ShoppingList
