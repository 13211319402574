import React from 'react'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import Fab from '@mui/material/Fab'
import Snackbar from '@mui/material/Snackbar'
import SaveIcon from '@mui/icons-material/Save'
import './RecipeCreator.css'


class RecipeCreator extends React.Component {
  constructor(props) {
    super(props)
    this.emptyStep = ""
    this.emptyIngredient = {
      quantity: "",
      unit: "",
      item: ""
    }
    this.emptySection = {
      ingredients: [this.emptyIngredient],
      steps: [this.emptyStep],
      title: ""
    }
    this.state = {
      sections: props.sections.length > 0 ? props.sections : [this.emptySection],
      title: props.title,
      saving: false,
      messageOpen: false
    }
  }
  updateTitle = (event) => {
    let title = event.target.value
    this.setState({
      title: title
    })
  }
  save = () => {
    this.setState({
      saving: true
    })
  }
  addSection = () => {
    let sections = JSON.parse(JSON.stringify(this.state.sections))
    sections.push(this.emptySection)
    this.setState({
      sections: sections
    })
  }
  removeSection = (index) => {
    let sections = JSON.parse(JSON.stringify(this.state.sections))
    sections.splice(index, 1)
    this.setState({
      sections: sections
    })
  }
  updateSection = (index, section) => {
    this.setState({
      saving: false
    })
    let sections = JSON.parse(JSON.stringify(this.state.sections))
    sections[index] = section
    this.setState({
      sections: sections
    })
  }
  closeSaveMessage = () => {
    this.setState({
      messageOpen: false
    })
    this.props.closeEditor()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.saving === true) {
      const data = {
        title: this.state.title,
        sections: this.state.sections
      }
      this.props.save(data)
    }
    if (this.props.result !== prevProps.result) {
      let message = "There was an error saving the file"
      if (this.props.result && 'id' in this.props.result) {
        message = "File was saved successfully"
      }
      this.setState({
        messageOpen: true,
        saveMessage: <span>{message}</span>
      })
    }
  }
  render() {
    let sections = this.state.sections.map((section, index) =>
      <RecipeSection
        key={index}
        id={index}
        data={section}
        removeSection={this.removeSection}
        saving={this.state.saving}
        update={this.updateSection}
      />
    )
    const vertical = 'top'
    const horizontal = 'center'
    return (
      <Container className="recipe-creator">
        <TextField
          label="Recipe Title"
          className="recipe-title"
          value={this.state.title}
          onChange={this.updateTitle}
          fullWidth
        />
        <Container className="recipe-sections">
          {sections}
        </Container>
        <Tooltip title="Add Section" placement="right">
          <IconButton onClick={this.addSection} size="large">
            <AddCircleIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Fab
          color="secondary"
          onClick={this.save}
          className="save-recipe-fab"
          variant="extended"
        >
          <SaveIcon />
          <span style={{ marginLeft: '8px' }}> Save</span>
        </Fab>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={this.state.messageOpen}
          message={this.state.saveMessage}
          onClose={this.closeSaveMessage}
          autoHideDuration={5000}
        />
      </Container>
    );
  }
}

class RecipeSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.data.title,
      ingredients: props.data.ingredients,
      steps: props.data.steps,
      id: props.id
    }
  }
  delete = () => {
    this.props.removeSection(this.state.id)
  }
  update = () => {
    const data = {
      title: this.state.title,
      ingredients: this.state.ingredients,
      steps: this.state.steps
    }
    this.props.update(this.state.id, data)
  }
  updateTitle = (event) => {
    let title = event.target.value
    this.setState({
      title: title
    })
  }
  addStep = () => {
    let steps = this.state.steps
    steps.push("")
    this.setState({
      steps: steps
    })
  }
  deleteStep = (index) => {
    let steps = [...this.state.steps]
    steps.splice(index, 1)
    this.setState({
      steps: steps
    })
  }
  updateStep = (index, step) => {
    let steps = this.state.steps
    steps[index] = step
    this.setState({
      steps: steps
    })
  }
  addIngredient = () => {
    let ingredients = this.state.ingredients
    ingredients.push({
      quantity: "",
      unit: "",
      item: ""
    })
    this.setState({
      ingredients: ingredients
    })
  }
  deleteIngredient = (index) => {
    let ingredients = this.state.ingredients
    ingredients.splice(index, 1)
    this.setState({
      ingredients: ingredients
    })
  }
  updateIngredient = (index, ingredient) => {
    let ingredients = this.state.ingredients
    ingredients[index] = ingredient
    this.setState({
      ingredients: ingredients
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.state.id) {
      let { title, ingredients, steps } = this.props.data
      let id = this.props.id
      this.setState({
        title: title,
        steps: steps,
        ingredients: ingredients,
        id: id
      })
    }
    if (this.props.saving) {
      this.update()
    }
  }
  render() {
    let steps = this.state.steps.map((step, index) =>
      <RecipeStep
        step={step}
        key={index}
        id={index}
        delete={this.deleteStep}
        saving={this.props.saving}
        update={this.updateStep}
      />
    )
    let ingredients = this.state.ingredients.map((ingredient, index) =>
      <RecipeIngredient
        ingredient={ingredient}
        key={index}
        id={index}
        delete={this.deleteIngredient}
        saving={this.props.saving}
        update={this.updateIngredient}
      />
    )
    return (
      <Container className="recipe-section">
        <TextField
          label="Section Title"
          className="recipe-section-title"
          value={this.state.title}
          onChange={this.updateTitle}
          fullWidth
        />
        <Container className="recipe-section-ingredients">
          <Typography variant="h5" component="h5" className="recipe-section-ingredients-title">Ingredients</Typography>
          {ingredients}
          <Tooltip title="Add Ingredient" placement="right">
            <IconButton onClick={this.addIngredient} size="large">
              <AddCircleIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Container>
        <Container className="recipe-section-steps">
          <Typography variant="h5" component="h5" className="recipe-section-instructions-title">Instructions</Typography>
          {steps}
          <Tooltip title="Add Step" placement="right">
            <IconButton onClick={this.addStep} size="large">
              <AddCircleIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Container>
        <Tooltip title="Delete Section" placement="right">
          <IconButton onClick={this.delete} size="large">
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      </Container>
    );
  }
}

class RecipeStep extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: props.step,
      id: props.id
    }
  }
  update = (event) => {
    let step = event.target.value
    this.setState({
      step: step
    })
  }
  delete = () => {
    this.props.delete(this.state.id)
  }
  save = () => {
    this.props.update(this.state.id, this.state.step)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.state.id || prevProps.step !== this.props.step) {
      let { step, id } = this.props
      this.setState({
        step: step,
        id: id
      })
    }
    if (this.props.saving) {
      this.save()
    }
  }
  render() {
    const { step, id } = this.state
    return (
      <ListItem
        key={id}
        dense
      >
        <TextField
          label="Instruction"
          className="recipe-step"
          value={step}
          margin="normal"
          onChange={this.update}
          fullWidth
          multiline
        />
        <Tooltip title="Delete Step" placement="right">
          <IconButton onClick={this.delete} size="large">
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
}

class RecipeIngredient extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ingredient: props.ingredient,
      id: props.id
    }
  }
  update = (event, source) => {
    let value = event.target.value
    let ingredient = JSON.parse(JSON.stringify(this.state.ingredient))
    ingredient[source] = value
    this.setState({
      ingredient: ingredient
    })
  }
  delete = () => {
    this.props.delete(this.state.id)
  }
  save = () => {
    this.props.update(this.state.id, this.state.ingredient)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.state.id || prevProps.ingredient !== this.props.ingredient) {
      let { ingredient, id } = this.props
      this.setState({
        ingredient: ingredient,
        id: id
      })
    }
    if (this.props.saving) {
      this.save()
    }
  }
  render() {
    const { ingredient, id } = this.state
    return (
      <ListItem
        key={id}
        dense
      >
        <TextField
          label="Quantity"
          className="recipe-ingredient"
          value={ingredient.quantity}
          onChange={(event) => this.update(event, "quantity")}
          margin="normal"
        />
        <TextField
          label="Unit"
          className="recipe-ingredient"
          value={ingredient.unit}
          onChange={(event) => this.update(event, "unit")}
          margin="normal"
        />
        <TextField
          label="Ingredient"
          className="recipe-ingredient"
          value={ingredient.item}
          onChange={(event) => this.update(event, "item")}
          margin="normal"
        />
        <Tooltip title="Delete Ingredient" placement="right">
          <IconButton onClick={this.delete} size="large">
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
}

export default RecipeCreator
