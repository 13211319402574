import React from 'react'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Tooltip from '@mui/material/Tooltip'
import './FileList.css'

class FileList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.open,
      files: props.files,
      selectedItem: 0,
      folderStack: []
    }
  }
  loadFile = (fileId) => {
    this.setState((state) => ({
      selectedItem: fileId
    }))
    this.props.loadFile(fileId)
  }
  nextFolder = (folderId, parentId) => {
    let folderStack = this.state.folderStack
    folderStack.push(parentId)
    this.setState(state => ({
      folderStack: folderStack
    }))
    this.openFolder(folderId)
  }
  previousFolder = () => {
    let folderStack = this.state.folderStack
    const folderId = folderStack.pop()
    this.setState(state => ({
      folderStack: folderStack
    }))
    this.openFolder(folderId)
  }
  openFolder = (folderId) => {
    const data = {
      action: 'picked',
      docs: [{ id: folderId }]
    }
    window.getFilesInFolder(data)
  }
  componentDidUpdate(prevProps, prevState) {
    let { open, files } = this.props
    if (open !== this.state.open || files !== this.state.files) {
      this.setState({
        open: open,
        files: files
      })
    }
  }
  render() {
    const { open, files, selectedItem, folderStack } = this.state
    return (
      <div
        onClick={this.props.closeDrawer}
      >
        <Drawer
          open={open}
        >
          {folderStack.length > 0 &&
            <Tooltip title="Back" placement="bottom">
              <IconButton
                edge="start"
                aria-haspopup="true"
                onClick={this.previousFolder}
                className="back-button"
                size="large">
                <KeyboardBackspaceIcon />
              </IconButton>
            </Tooltip>
          }
          <List>
            {files.map((file) => {
              if ('files' in file) {
                let subfiles = file.files.map(subfile => (
                  <ListItemButton
                    key={subfile.id}
                    onClick={() => this.loadFile(subfile.id)}
                    className="subfile"
                    selected={selectedItem === subfile.id}
                  >
                    <ListItemText primary={subfile.name} />
                  </ListItemButton>
                ))
                return (
                  <div key={file.id}>
                    <ListItemButton
                      key={file.id}
                      className="folder"
                      onClick={() => this.nextFolder(file.id, file.parent)}
                    >
                      <ListItemText primary={file.name} />
                    </ListItemButton>
                    {subfiles}
                    <Divider
                      variant="middle"
                    />
                  </div>
                )
              }
              return (
                <ListItemButton
                  key={file.id}
                  onClick={() => this.loadFile(file.id)}
                >
                  <ListItemText primary={file.name} />
                </ListItemButton>
              )
            })}
          </List>
        </Drawer>
      </div>
    );
  }
}

export default FileList
