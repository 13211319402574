import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";

const ScaleField = withStyles({
  root: {
    color: 'white',
    width: '100px',
    'min-width': '75px',
    '& input': {
      color: 'white'
    },
    '& label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white'
    },
    '& .MuiInput-underline': {
      borderBottomColor: 'white'
    }
  }
})(TextField);

export default ScaleField
