import * as math from 'mathjs'

const IMPERIAL_UNITS = [
  "cup",
  "halfcup",
  "quartercup",
  "tablespoon",
  "teaspoon",
  "halfteaspoon",
  "quarterteaspoon",
  "eighthteaspoon"
]

let generic_ingredient = (value, text, metric = "gram", odd_units = []) => (
  {
    text: text,
    units: {
      stupid: {
        cup: value,
        halfcup: 1 / 2,
        quartercup: 1 / 4,
        tablespoon: 1 / 16,
        teaspoon: 1 / 48,
        halfteaspoon: 1 / 96,
        quarterteaspoon: 1 / 192,
        eighthteaspoon: 1 / 384
      },
      metric: metric,
      main: "cup"
    }
  }
)

const ingredients = {
  flour: generic_ingredient(125, "Flour"),
  "T45 flour": generic_ingredient(125, "T45 Flour"),
  "T55 flour": generic_ingredient(125, "T55 Flour"),
  butter: generic_ingredient(226, "Butter"),
  "dry butter": generic_ingredient(226, "Dry Butter"),
  "salted butter": generic_ingredient(226, "Salted Butter"),
  "brown sugar": generic_ingredient(200, "Brown Sugar"),
  sugar: generic_ingredient(200, "Sugar"),
  "icing sugar": generic_ingredient(125, "Icing Sugar"),
  "cocoa powder": generic_ingredient(118, "Cocoa Powder")
}

let buildUnit = (name, units) => {
  let imperial_units = units.stupid
  let main_imperial = units.main
  let metric = units.metric
  let value = imperial_units[main_imperial]
  let conversion_rules = {}
  name = name.replace(" ", "")
  conversion_rules[`${name}${main_imperial}`] = {
    definition: `${value}${metric}`
  }
  for (let imperial in imperial_units) {
    if (imperial === main_imperial) {
      continue
    }
    value = imperial_units[imperial]
    conversion_rules[`${name}${imperial}`] = {
      definition: `${value}${name}${main_imperial}`
    }
  }
  math.createUnit(conversion_rules)
}

let isVolume = (unit) => {
  if (!unit) {
    return false
  }
  return isSameBase(unit, "ml")
}

let canConvert = (name, source_unit, target_unit = null) => {
  if (!(name in ingredients)) {
    return false
  }
  let ingredient = ingredients[name]
  let imperial_units = ingredient.units.stupid
  let metric = ingredient.units.metric
  if (target_unit == null) {
    target_unit = metric
  }
  if (source_unit === metric) {
    if (target_unit in imperial_units) {
      return true
    }
    return isSameBase(source_unit, metric)
  }
  if (source_unit in imperial_units) {
    if (target_unit in imperial_units) {
      return true
    }
    return isSameBase(target_unit, metric)
  }
  return isSameBase(source_unit, metric)
}

let value = (name, quantity, unit) => {
  let ingredient = ingredients[name]
  let imperial_units = ingredient.units.stupid
  let metric = ingredient.units.metric
  let result
  if (isSameBase(unit, metric)) {
    result = math.unit(quantity, unit)
  }
  else if (unit in imperial_units) {
    result = math.unit(quantity, `${name.replace(" ", "")}${unit}`)
  }
  return result
}

let isSameBase = (a, b) => {
  let result = false
  try {
    result = math.unit(1, a).equalBase(math.unit(1, b))
  }
  catch (error) {
  }
  return result
}

let isSpiceLike = item => (
  [
    "baking soda",
    "baking powder",
    "salt"
  ].includes(item)
)

let convertUnit = (name, quantity, source_unit, target_unit = null, forceConvertSpiceLike = false) => {
  if (!canConvert(name, source_unit, target_unit)) {
    // console.error(`${name} is not a valid ingredient for conversion`)
    if (isVolume(source_unit) && (forceConvertSpiceLike || !isSpiceLike(name))) {
      return {
        value: [math.round(math.unit(quantity, source_unit).toNumber("ml"), 2)],
        unit: ["ml"]
      }
    }
    return null
  }
  let source_value = value(name, quantity, source_unit)
  if (target_unit == null) {
    target_unit = ingredients[name].units.metric
    return {
      value: [math.round(source_value.toNumber(target_unit), 2)],
      unit: [target_unit]
    }
  }
  const units = IMPERIAL_UNITS.map(unit => `${name.replace(" ", "")}${unit}`)
  return splitImperial(units, source_value)
}

let toMetric = (name, quantity, source_unit, forceConvertSpiceLike = false) => {
  return convertUnit(name, quantity, source_unit, null, forceConvertSpiceLike)
}

let splitImperial = (units, source_value) => {
  const values = source_value.splitUnit(units)
  let [
    cup,
    halfcup,
    quartercup,
    tbsp,
    tsp,
    halftsp,
    quartertsp,
    eighthtsp
  ] = values.map((unit, index) => (
    unit.toNumber(units[index])
  ))
  cup = math.round(cup + 0.5 * halfcup + 0.25 * quartercup, 2)
  tbsp = math.round(tbsp, 2)
  tsp = math.round(tsp + 0.5 * halftsp + 0.25 * quartertsp + 0.125 * eighthtsp, 2)
  return {
    value: [cup, tbsp, tsp],
    unit: ["cup", "tablespoon", "teaspoon"]
  }
}

let toImperial = (name, quantity, source_unit) => {
  if (!canConvert(name, source_unit)) {
    //console.error(`${name} is not a valid ingredient for conversion`)
    if (isVolume(source_unit)) {
      return splitImperial(IMPERIAL_UNITS, math.unit(quantity, source_unit))
    }
    return null
  }
  let target_unit = Object.keys(ingredients[name].units.stupid)[0]
  return convertUnit(name, quantity, source_unit, target_unit)
}

let units = () => {
  for (let ingredient in ingredients) {
    buildUnit(ingredient, ingredients[ingredient].units)
  }
  math.createUnit({
    halfcup: '0.5 cup'
  })
  math.createUnit({
    quartercup: '0.25 cup'
  })
  math.createUnit({
    halfteaspoon: '0.5 teaspoon'
  })
  math.createUnit({
    quarterteaspoon: '0.25 teaspoon'
  })
  math.createUnit({
    eighthteaspoon: '0.125 teaspoon'
  })
}

units()

export {
  convertUnit,
  toMetric,
  toImperial
}
