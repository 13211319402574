import React from 'react'
import RecipeDisplay from './RecipeDisplay'
import RecipeCreator from './RecipeCreator'
import Header from './AppBar'
import FileList from './FileList'
import ShoppingList from './ShoppingList'
import GDrive from './gdrive'
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import purple from '@mui/material/colors/deepPurple'
// import blue from '@mui/material/colors/blue'
import './App.css'

const theme = createTheme({
  palette: {
    primary: {
      main: '#007893'
    },
    secondary: {
      main: '#fec007'
    },
  },
})

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      metric: true,
      scale: 1,
      recipeDisplayed: false,
      recipeData: {
        sections: [],
        title: ""
      },
      files: [],
      fileListOpen: false,
      recipeEditing: false,
      shoppingListOpen: false,
      shoppingList: {}
    }
  }
  setMetric = (metric) => {
    this.setState({
      metric: metric
    })
  }
  setScale = (scale) => {
    this.setState({
      scale: scale
    })
  }
  getRecipeData = (recipe) => {
    let sections = recipe.sections
    for (let section of sections) {
      try {
        section.title = decodeURIComponent(escape(section.title))
      }
      catch (e) {
      }
      for (let step in section.steps) {
        try {
          section.steps[step] = decodeURIComponent(escape(section.steps[step]))
        }
        catch (e) {
        }
      }
    }
    let title = recipe.title
    try {
      title = decodeURIComponent(escape(title))
    }
    catch (e) {
    }
    return {
      sections: sections,
      title: title
    }
  }
  renderRecipe = (recipe, fileId) => {
    if (this.state.shoppingListOpen) {
      this.toggleShoppingList()
    }
    const { sections, title } = this.getRecipeData(recipe)
    if (sections !== undefined) {
      this.setState({
        recipeData: {
          sections: sections,
          title: title,
          fileId: fileId
        },
        recipeDisplayed: true
      })
    }
  }
  saveRecipe = (recipe) => {
    const callback = (data) => {
      this.setState({
        saveResult: data
      })
    }
    window.saveFile(
      recipe.title,
      JSON.stringify(recipe, null, 2),
      callback
    )
  }
  openFolder = () => {
    window.openFolder(this.getFiles)
  }
  getFiles = (files) => {
    this.setState((state) => {
      return {
        files: files.map((file) => {
          let file_data = {
            name: file.name.slice(0, -4),
            id: file.id
          }
          if ('files' in file) {
            file_data.files = file.files.map((subfile) => (
              {
                name: subfile.name.slice(0, -4),
                id: subfile.id
              }
            ))
            file_data.name = file.name
            file_data.parent = file.parent
          }
          return file_data
        }),
        fileListOpen: true
      }
    })
  }
  loadFile = (fileId, callback = this.renderRecipe) => {
    window.getFileContents(fileId, callback)
  }
  closeDrawer = () => {
    this.setState({
      fileListOpen: false
    })
  }
  toggleDrawer = () => {
    this.setState({
      fileListOpen: !this.state.fileListOpen
    })
  }
  toggleEditor = () => {
    if (this.state.shoppingListOpen) {
      return
    }
    this.setState({
      recipeEditing: !this.state.recipeEditing
    })
    setTimeout(
      () => {
        if (this.state.recipeEditing) {
          window.scrollTo(
            0,
            document.getElementsByClassName("recipe-creator")[0].offsetTop
          )
        }
        else {
          window.scrollTo(0, 0)
        }
      },
      25
    )
  }
  toggleShoppingList = () => {
    this.setState({
      shoppingListOpen: !this.state.shoppingListOpen
    })
  }
  addToShoppingList = (fileId) => {
    if (!(fileId in this.state.shoppingList)) {
      this.loadFile(fileId, this.addToShoppingListCallback)
    }
  }
  addToShoppingListCallback = (recipe, fileId) => {
    let list = this.state.shoppingList
    list[fileId] = this.getRecipeData(recipe)
    this.setState({
      shoppingList: list
    })
  }
  deleteFromShoppingList = (fileId) => {
    let list = this.state.shoppingList
    delete list[fileId]
    this.setState({
      shoppingList: list
    })
  }
  render() {
    const {
      metric,
      scale,
      recipeDisplayed,
      recipeData,
      files,
      fileListOpen,
      recipeEditing,
      saveResult,
      shoppingListOpen,
      shoppingList
    } = this.state
    return (
      <div
        className="App"
      >
        <ThemeProvider theme={theme}>
          <Header
            metric={metric}
            scale={scale}
            setMetric={this.setMetric}
            setScale={this.setScale}
            openFolder={this.openFolder}
            toggleMenu={this.toggleDrawer}
            menuOpen={fileListOpen}
            edit={this.toggleEditor}
            shoppingList={Object.keys(shoppingList)}
            toggleShoppingList={this.toggleShoppingList}
          />
          <FileList
            files={files}
            open={fileListOpen}
            loadFile={this.loadFile}
            closeDrawer={this.closeDrawer}
          />
          {recipeDisplayed && !shoppingListOpen &&
            <RecipeDisplay
              {...recipeData}
              scale={scale}
              metric={metric}
              addToShoppingList={this.addToShoppingList}
            />
          }
          {recipeEditing && !shoppingListOpen &&
            <RecipeCreator
              {...recipeData}
              save={this.saveRecipe}
              result={saveResult}
              closeEditor={this.toggleEditor}
            />
          }
          {shoppingListOpen &&
            <ShoppingList
              list={shoppingList}
              files={files}
              close={this.toggleShoppingList}
              delete={this.deleteFromShoppingList}
              metric={metric}
            />
          }
          <GDrive callback={this.renderRecipe} openFolder={this.openFolder} />
        </ThemeProvider>
      </div>
    )
  }
}

export default App;
